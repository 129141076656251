import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import profilePhoto from "../images/profile.jpg";
import hunterLeaman from "../images/hunter-leaman.png";

// TODO: need to handle the <sourcecode> and <adsense> tags

export default ({ data }) => {
  const post = data.markdownRemark;

  const tags =
    post.frontmatter.tags &&
    post.frontmatter.tags.length &&
    post.frontmatter.tags.filter((tag) => !tag.startsWith(`#`));

  return (
    <Layout>
      <SEO keywords={tags} title={`${post.frontmatter.title}`} />

      <article>
        <header className="mt-10 text-center w-full">
          <h1 className="text-5xl leading-tight font-bold mb-4">
            {post.frontmatter.title}
          </h1>

          <div className="mb-16 text-md font-thin flex flex-wrap items-center">
            <div className="w-full">
              {post.frontmatter.prettyDate}
              <svg
                className="inline-block ml-8"
                height="18"
                viewBox="0 0 24 24"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.59 13.41A1.98 1.98 0 0 1 2 12V7a5 5 0 0 1 5-5h4.99c.53 0 1.04.2 1.42.59l8 8a2 2 0 0 1 0 2.82l-8 8a2 2 0 0 1-2.82 0l-8-8zM20 12l-8-8H7a3 3 0 0 0-3 3v5l8 8 8-8zM7 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
              </svg>
              {tags &&
                tags
                  .map((tag) => (
                    <span className="ml-2" key={tag}>
                      {tag}
                    </span>
                  ))
                  .reduce((prev, curr) => [prev, `, `, curr])}
            </div>
          </div>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />

        <footer className="mb-10">
          <div className="pt-2 mt-12 mb-4 border-t border-solid border-gray-300 font-thin text-xs">
            WRITTEN BY
          </div>

          {post.frontmatter.author && (
            <section className="flex flex-col md:flex-row items-start text-lg">
              <img
                alt="profile"
                className="mr-4 h-20 w-20 rounded-full"
                src={post.frontmatter.profileUrl || hunterLeaman}
              />
              <div>
                <div className="text-sm font-bold">
                  {post.frontmatter.author}
                </div>
                <p className="text-sm pt-2">{post.frontmatter.bio}</p>
              </div>
            </section>
          )}

          {!post.frontmatter.author && (
            <section className="flex flex-col md:flex-row items-start text-lg">
              <img
                alt="profile"
                className="mr-4 h-20 w-20 rounded-full"
                src={profilePhoto}
              />
              <div>
                <div className="text-sm font-bold">Ashteya Biharisingh</div>
                <p className="text-sm pt-2">
                  Full stack developer who likes to build mobile apps and read
                  books.
                </p>
              </div>
            </section>
          )}
        </footer>
      </article>
    </Layout>
  );
};

// this query is run on build for each post, and is passed to the above component
// for rendering
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        tags
        prettyDate: date(formatString: "D MMMM YYYY", locale: "us")
        author
        bio
        profileUrl
      }
    }
  }
`;
